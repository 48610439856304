<template>
  <!-- evaluation-h5 - 渠道管理 -->
  <div class="pageContol">
    <div class="framePage">
      <!-- 头部面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">测评管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">渠道管理</a>
        </span>
      </div>
      <!-- 主体区域 -->
      <div class="framePage-body">
        <!-- 搜索区域 -->
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="渠道名称" class="searchboxItem ci-full">
              <span class="itemLabel">渠道名称:</span>
              <el-input v-model="searchForm.channelName" clearable placeholder="请输入渠道名称" type="text" size="small" />
            </div>
            <div title="联系人" class="searchboxItem ci-full">
              <span class="itemLabel">联系人:</span>
              <el-input v-model="searchForm.contacts" clearable placeholder="请输入联系人" type="text" size="small" />
            </div>
            <div title="联系电话" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">联系电话:</span>
              <el-input v-model="searchForm.phone" clearable placeholder="请输入联系电话" type="text" size="small" />
            </div>
            <div title="机构名称" class="searchboxItem ci-full flexcc" v-if="userJson.roleId == '1' || userJson.roleId == '-1'">
              <span class="itemLabel">机构名称:</span>
              <el-select size="small" v-model="searchForm.resCompId" remote :remote-method="getCompanyList" filterable clearable
                placeholder="请至少输入两个字搜索">
                <el-option v-for="item in CompanyList" :key="item.compId" :label="item.compName"
                  :value="item.compId"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="创建时间" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker v-model="searchForm.createTime" size="small" type="daterange" style="width:5rem"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd"
                value-format="yyyy-MM-dd" />
            </div>
            <div class="searchboxItem">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" @click="tAddEditChannelManage()" round>新增</el-button>
            </div>
          </div>
        </div>
        <!-- 列表区域 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="left" type="index" :index="indexMethod" width="50" />
              <el-table-column prop="channelName" label="渠道名称" align="left" />
              <el-table-column prop="contacts" label="联系人" align="left" />
              <el-table-column prop="phone" label="联系电话" align="left" />
              <el-table-column prop="resCompName" label="机构名称" align="left" v-if="userJson.roleId == '1' || userJson.roleId == '-1'" />
              <el-table-column label="状态" align="left">
                <template slot-scope="scope">
                  <el-switch v-model="scope.row.usable" active-color="#13ce66" inactive-color="#ff4949"
                    @change="setUsable(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column label="创建日期" align="left">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="150px" fixed="right">
                <template slot-scope="scope" class="flexcc">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="tAddEditChannelManage(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="downloadQrCode(scope.row)">下载二维码</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <!-- 分页器 -->
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 新增编辑 -->
    <el-dialog :title="channelDialogData.channelId ? '编辑渠道' : '新增渠道'" :visible.sync="channelDialogData.dialogVisible"
      @closed="channechanneDialogClosed" width="800px">
      <el-form ref="channelDialogData" :rules="channelDialogRules" :model="channelDialogData" size="small"
        label-width="100px">
        <el-form-item label="渠道名称" prop="channelName">
          <el-input v-model="channelDialogData.channelName" placeholder="请输入渠道名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input v-model="channelDialogData.contacts" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话 " prop="phone">
          <el-input v-model="channelDialogData.phone" placeholder="请输入联系电话 "></el-input>
        </el-form-item>
        <el-form-item label="机构名称" prop="resCompId" v-if="userJson.roleId == '1' || userJson.roleId == '-1'">
          <el-select size="small" v-model="channelDialogData.resCompId" remote :remote-method="getCompanyListAddEdit" filterable clearable
            placeholder="请至少输入两个字搜索">
            <el-option v-for="item in channelDialogData.CompanyList" :key="item.compId" :label="item.compName"
              :value="item.compId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="channelDialogData.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="customBtn" round @click="channechanneDialogClosed">取 消</el-button>
        <el-button class="customBtn" round type="primary" @click="tAddEditChannelManageDetermine">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 下载二维码 -->
    <el-dialog title="下载二维码" :visible.sync="downloadQrCodeData.dialogVisible"
      @closed="downloadQrCodeDialogClosed" width="400px">
      <img :src="downloadQrCodeData.viewURL" style="width: 300px;height: 300px; display: block;margin: auto;" alt="">
      <div slot="footer" class="dialog-footer">
        <el-button class="customBtn" round @click="downloadQrCodeDialogClosed">取 消</el-button>
        <el-button class="customBtn" round type="primary" @click="downloadQrCodeDataOk">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "evaluationManagement_channelManage",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      // 搜索表单
      searchForm: {
        channelName: "", // 渠道名称
        contacts: "", // 联系人
        phone: "", // 联系电话
        createTime: "", // 创建时间
        resCompId: "", // 机构名称
      },
      // 机构名称
      CompanyList: [],
      // 新增编辑 - 弹框数据
      channelDialogData: {
        dialogVisible: false, // 弹框状态
        CompanyList: [], // 机构列表
        channelId:"", // 渠道id
        channelName:"", // 渠道名称
        contacts:"", // 联系人
        phone:"", // 联系人电话
        resCompId: "", // 机构名称
        remarks:"", // 备注
      },
      // 新增编辑 - 弹框数据校验
      channelDialogRules: {
        channelName: [
          { required: true, message: "请输入渠道名称", trigger: "blur" }
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "change" }
        ],
        phone: [
          { required: true, validator: this.$validatePhone, trigger: "blur" }
        ],
        resCompId: [
          { required: true, message: "请选择机构名称", trigger: "change" }
        ],
      },
      // 下载二维码 - 弹框数据
      downloadQrCodeData: {
        dialogVisible: false, // 弹框状态
        downloadURL: "", // 下载地址
        viewURL: "" // 预览地址
      }
    }
  },
  computed: {
    ...mapGetters({
      userJson: "getUser"
    }),
  },
  created() {

  },
  methods: {
    // 机构名称 - 检索用
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 机构名称 - 新增编辑用
    getCompanyListAddEdit(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.channelDialogData.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.channelDialogData.CompanyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchForm.channelName) {
        params.channelName = this.searchForm.channelName;
      }
      if (this.searchForm.contacts) {
        params.contacts = this.searchForm.contacts;
      }
      if (this.searchForm.phone) {
        params.phone = this.searchForm.phone;
      }
      if (this.searchForm.resCompId) {
        params.resCompId = this.searchForm.resCompId;
      }
      if (this.searchForm.createTime) {
        params.startDate = this.searchForm.createTime[0];
        params.endDate = this.searchForm.createTime[1];
      }
      this.doFetch({
        url: "/biz/hr/evaluate/psy/channel/pageList",
        params,
        pageNum
      }, true, 6);
    },
    // 切换状态
    setUsable(row) {
      this.$post("/biz/hr/evaluate/psy/channel/usable", { channelId: row.channelId }, 3000, true, 6)
        .then(res => {
          this.getData(this.pageNum);
        })
        .catch(() => {
          return;
        });
    },
    // 新增编辑 - 弹框
    tAddEditChannelManage(row){
      if(row) {
        this.channelDialogData = { ...this.channelDialogData, ...row }
        this.getCompanyListAddEdit(row.resCompName);
      }
      this.channelDialogData.dialogVisible = true;
    },
    // 新增编辑 - 弹框 - 确定
    tAddEditChannelManageDetermine(){
      this.$refs['channelDialogData'].validate((valid) => {
        if (valid) {
          let url = '';
          let params = {
            channelName:this.channelDialogData.channelName, // 渠道名称
            contacts:this.channelDialogData.contacts, // 联系人
            phone:this.channelDialogData.phone, // 联系人电话
            resCompId: this.channelDialogData.resCompId, // 机构名称
            remarks:this.channelDialogData.remarks, // 备注
          };
          if(this.channelDialogData.channelId){
            url = '/biz/hr/evaluate/psy/channel/modify';
            params.channelId = this.channelDialogData.channelId;
          } else {
            url = '/biz/hr/evaluate/psy/channel/insert'
          }
          this.$post(url, params, 3000, true, 6)
            .then(res => {
              if (res.status == 0) {
                this.getData(this.pageNum);
                this.channechanneDialogClosed();
              }
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 新增编辑 - 弹框 - 关闭
    channechanneDialogClosed() {
      this.$refs['channelDialogData'].resetFields();
      this.channelDialogData = this.$options.data().channelDialogData;
    },
    // 下载二维码 - 弹框
    downloadQrCode(row){
      this.$post('/biz/hr/evaluate/psy/channel/getQrCode', { channelId: row.channelId }, 3000, true, 6)
        .then(res => {
          console.log(res)
          if (res.status == 0) {
            this.downloadQrCodeData = {
              ...this.downloadQrCodeData, ...res.data
            }
            this.downloadQrCodeData.dialogVisible = true;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 下载二维码 - 确定
    downloadQrCodeDataOk(){
      window.open(this.downloadQrCodeData.downloadURL);
    },
    // 下载二维码 - 关闭
    downloadQrCodeDialogClosed() {
      this.downloadQrCodeData = this.$options.data().downloadQrCodeData;
    },
  },
}
</script>

<style lang="less" scoped></style>